<template>
  <v-row justify="center">
    <v-dialog v-model="dialogVisible" style="overflow-x: auto" max-width="1400">
      <v-card>
        <v-toolbar class="v-sheet theme--dark v-toolbar secondary">
          <v-toolbar-title>
            {{ title }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeSpecialRefundModal()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-form ref="refundForm" class="pa-4">
          <v-row>
            <v-col cols="12">
              <v-data-table
                :headers="orderItemHeaders"
                :items="dataItems"
                item-key="id"
                show-expand
                class="elevation-1 mt-3"
                disable-pagination
                hide-default-footer>
                <template v-slot:[`expanded-item`]="{ headers, item }">
                  <td v-if="item.type === 'journey'" :colspan="headers.length">
                    <v-timeline align-top dense>
                      <v-timeline-item v-for="(trip, i) in item.itinerary" :key="i" small>
                        <v-card :color="itineraryIsNrt(trip) ? 'primary lighten-4' : 'grey lighten-4'">
                          <v-card-title class="text-subtitle-2">
                            <span>{{ trip.departure.datetime | datetime }} • {{ trip.departure.locationName }}</span>
                            <v-icon small style="margin: 0 10px">mdi-arrow-right</v-icon>
                            <span>{{ trip.arrival.datetime | datetime }} • {{ trip.arrival.locationName }}</span>
                            <v-spacer />
                            <span v-if="itineraryIsNrt(trip)" class="font-weight-bold">NRT Fare</span>
                          </v-card-title>

                          <v-card-subtitle>
                            <span>{{ trip.operator.name }} • {{ trip.product.name }}</span>
                            <span v-if="trip.vehicle.name || trip.transportId">
                              <v-icon small style="margin: 0 5px">mdi-slash-forward </v-icon>{{ trip.vehicle.name }}
                              {{ trip.transportId }}
                            </span>
                          </v-card-subtitle>

                          <v-card-text>
                            <div class="text--primary">
                              <v-list>
                                <v-list-item
                                  v-for="(passenger, i) in trip.passengers"
                                  :key="i"
                                  v-show="
                                    (item.isPaid && ['journey', 'railpass', 'article'].includes(item.type)) ||
                                    (item.type == 'distribution' && item.category == 'physical') ||
                                    (item.type == 'giftcard' && item.purpose == 'purchasing')
                                  ">
                                  <v-list-item-title class="d-flex align-center" style="gap: 2rem">
                                    <div class="d-inline">
                                      <span v-if="passenger.personalInfo">
                                        {{ passenger.personalInfo.firstName }}
                                        {{ passenger.personalInfo.lastName }}
                                      </span>
                                    </div>
                                    <div class="d-inline">
                                      <span>
                                        <v-icon small style="margin: 0 5px">mdi-seat-recline-normal</v-icon>
                                        {{ passenger.seatNumber || "-" }}
                                      </span>

                                      <span>
                                        <v-icon small style="margin: 0 5px">mdi-train-car-passenger</v-icon>
                                        {{ passenger.carriageNumber || "-" }}
                                      </span>

                                      <span v-if="passenger.ageGroup && passenger.ageGroup.name">
                                        <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>
                                        {{ passenger.ageGroup.name }}
                                      </span>

                                      <span v-if="passenger.comfort">
                                        <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>
                                        {{ getTripPassengerSeatDetailsString(passenger) }}
                                      </span>
                                    </div>
                                  </v-list-item-title>

                                  <!-- Passenger's price, shown once per external ID in the itinerary -->
                                  <div v-show="passenger.statusDisability" class="font-weight-bold">
                                    <v-chip
                                      color="warning"
                                      class="text-capitalize priceValue"
                                      label
                                      small
                                      dark
                                      style="margin: 0 10px">
                                      {{ passenger.price.plainText }}
                                    </v-chip>
                                  </div>

                                  <div class="d-flex align-center">
                                    <div class="status">
                                      <v-chip
                                        v-if="passenger.status !== 'pending' && passenger.status !== 'completed'"
                                        label
                                        class="text-uppercase"
                                        small
                                        style="width: 160px"
                                        :style="{
                                          color: ['cancelled', 'refunded'].includes(passenger.status)
                                            ? 'white'
                                            : 'auto',
                                        }"
                                        :color="getPassengerStatusColor(passenger.status)">
                                        {{ passenger.status }}:
                                        {{ passenger.cancelInformation.amount.plainText }}
                                      </v-chip>
                                    </div>
                                    <div>
                                      <span class="pr-4 pl-2" style="margin-right: 13px"
                                        ><span>
                                          {{ getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger) }}
                                        </span>
                                      </span>
                                    </div>
                                    <div class="d-flex" style="width: 120px">
                                      <v-tooltip :disabled="!isSynced(passenger)" max-width="500" color="black" top>
                                        <template v-slot:activator="{ on, attrs }">
                                          <v-text-field
                                            v-show="passenger.statusDisability"
                                            @input="updateSyncedAmount(passenger, $event)"
                                            :value="getSyncedAmount(passenger)"
                                            :suffix="passenger.cancelInformation.amount.currency"
                                            label="Amount"
                                            type="number"
                                            solo
                                            hide-details
                                            dense
                                            outlined
                                            v-bind="attrs"
                                            v-on="on" />
                                        </template>
                                        <span>
                                          There are multiple passengers with the external row ID of
                                          <b>{{ getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger) }}</b>
                                          on different itineraries. For these passengers, the input fields will be
                                          automatically filled.
                                        </span>
                                      </v-tooltip>
                                    </div>
                                  </div>
                                </v-list-item>
                              </v-list>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </td>

                  <td v-else-if="item.type === 'taxi'" :colspan="headers.length">
                    <v-timeline align-top dense>
                      <v-timeline-item small>
                        <v-card color="grey lighten-4">
                          <v-card-title class="text-subtitle-2">
                            <span
                              >{{ taxiItem(item).startNode.time | datetimeUTC }} •
                              {{ taxiItem(item).startNode.name }}</span
                            >
                            <v-icon small style="margin: 0 10px">mdi-arrow-right</v-icon>
                            <span
                              >{{ taxiItem(item).endNode.time | datetimeUTC }} • {{ taxiItem(item).endNode.name }}</span
                            >
                          </v-card-title>

                          <v-card-subtitle>
                            <span
                              >{{ getTaxiName(taxiItem(item)) }} •
                              {{ taxiItem(item).distanceMeters | metersToKilometers }} •
                              {{ taxiItem(item).durationSeconds | secondsToMinutes }}</span
                            >
                          </v-card-subtitle>

                          <v-card-text>
                            <div class="text--primary">
                              <v-list>
                                <v-list-item v-for="(passenger, i) in taxiItem(item).passengers" :key="i">
                                  <v-list-item-title>
                                    <div class="d-flex align-center">
                                      <div class="name" style="min-width: 300px">
                                        <span>
                                          {{ passenger.firstName }}
                                          {{ passenger.lastName }}
                                        </span>
                                        <v-chip v-if="passenger.isContact" small color="primary">
                                          Contact Person
                                        </v-chip>
                                      </div>
                                      <span class="d-flex align-center">
                                        <v-icon small style="margin: 0 5px">mdi-phone</v-icon>
                                        {{ passenger.phone || "-" }}
                                      </span>

                                      <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>

                                      <span class="d-flex align-center">
                                        <v-icon small style="margin: 0 5px">mdi-email</v-icon>
                                        {{ passenger.email || "-" }}
                                      </span>

                                      <v-icon small style="margin: 0 5px">mdi-slash-forward</v-icon>

                                      <span class="d-flex align-center">
                                        <v-icon small style="margin: 0 5px">mdi-cake-variant</v-icon>
                                        {{ passenger.birthdate || "-" }}
                                      </span>
                                    </div>
                                  </v-list-item-title>
                                </v-list-item>
                              </v-list>
                            </div>
                            <v-card
                              v-if="taxiItem(item).startNode.description"
                              outlined
                              class="d-flex align-start mt-5 pa-3 font-weight-bold">
                              <v-icon color="accent" class="mr-2">mdi-information</v-icon>
                              <div class="grey--text text--darken-2">
                                <div v-html="taxiItem(item).startNode.description" />
                              </div>
                            </v-card>
                            <v-card
                              outlined
                              v-if="taxiItem(item).infoToDriver"
                              class="d-flex align-start mt-5 pa-3 font-weight-bold">
                              <v-icon color="blue-grey" class="mr-2">mdi-note-text-outline</v-icon>
                              <span class="font-weight-bold mr-1">Note for the driver: </span>
                              <span class="grey--text text--darken-2"> {{ taxiItem(item).infoToDriver }}</span>
                            </v-card>
                          </v-card-text>
                        </v-card>
                      </v-timeline-item>
                    </v-timeline>
                  </td>

                  <td v-else-if="item.type === 'article' || item.type === 'distribution'" :colspan="headers.length">
                    <div class="my-5 secondary--text" v-if="item.description">
                      {{ item.description }}
                    </div>

                    <v-container v-if="item.tickets && item.tickets.length > 0">
                      <v-simple-table>
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">Box Number</th>
                              <th class="text-left">Paper Number</th>
                              <th class="text-left">Used Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="ticket in item.tickets" :key="ticket.paperNumber">
                              <td>{{ ticket.boxNumber }}</td>
                              <td>{{ ticket.paperNumber }}</td>
                              <td>{{ ticket.usedDate }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </v-container>
                  </td>
                  <td v-if="item.type === 'railpass'" :colspan="headers.length">
                    <div class="py-4">
                      <v-simple-table class="elevation-1">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left primary--text">Comfort</th>
                              <th class="text-left primary--text">Age Group</th>
                              <th class="text-left primary--text">Passenger</th>
                              <th class="text-left primary--text">Start Date</th>
                              <th class="text-left primary--text">Stop Date</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr :key="item.name">
                              <td>{{ item.comfort.name }}</td>
                              <td>{{ item.ageGroup.name }}</td>
                              <td>
                                {{ item.passenger.firstName }}
                                {{ item.passenger.lastName }}
                              </td>
                              <td>{{ item.startDate }}</td>
                              <td>{{ item.stopDate }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </td>
                  <td v-if="item.type === 'giftcard'" :colspan="headers.length">
                    <div class="py-4">
                      <v-simple-table class="elevation-1">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left primary--text">Purpose</th>
                              <th class="text-left primary--text">Code</th>
                              <th class="text-left primary--text">Expiry date</th>
                              <th class="text-left primary--text">Remaining Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr :key="item.name">
                              <td>{{ item.purpose }}</td>
                              <td>{{ item.giftCard.code }}</td>
                              <td>{{ item.giftCard.expires }}</td>
                              <td>{{ item.giftCard.remainingAmount }}</td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                    </div>
                  </td>
                </template>

                <template v-slot:[`item.type`]="{ item }">
                  <div style="display: flex">
                    <v-chip
                      :color="getTypeColor(item.type)"
                      style="width: 100%"
                      class="text-capitalize font-weight-medium"
                      label
                      small
                      dark>
                      <span :class="item.type === 'taxi' ? 'taxi-text' : ''">
                        {{ item.type }}
                      </span>
                    </v-chip>
                    <span class="ml-2" v-if="isItemPrinted(item.id)">
                      <v-icon>mdi-printer</v-icon>
                    </span>
                    <span
                      class="ml-2 d-flex align-center"
                      v-if="item.isInterrailSeatReservation && item.type === 'journey' && item.type === 'railpass'">
                      <img src="@/assets/interrail-logo.png" width="20" height="18" alt="" />
                    </span>
                  </div>
                </template>

                <template v-slot:[`item.status`]="{ item }">
                  <v-chip
                    v-if="item.status"
                    :color="getStatusColor(item.status)"
                    style="width: 100%"
                    class="text-capitalize"
                    label
                    small
                    outlined>
                    {{ item.status.replace("_", " ") }}
                  </v-chip>
                </template>

                <template v-slot:[`item.itemdata`]="{ item }">
                  <div v-if="item.type === 'journey'">
                    <span class="font-weight-medium"
                      >{{ item.departure.datetime | datetime }} • {{ item.departure.locationName }}</span
                    >
                    <v-icon class="mx-2" small>mdi-arrow-right</v-icon>
                    <span class="font-weight-medium"
                      >{{ item.arrival.datetime | datetime }} • {{ item.arrival.locationName }}</span
                    >
                  </div>

                  <div v-else-if="item.type === 'taxi'" style="text-wrap: nowrap">
                    <span class="font-weight-medium">
                      {{ taxiItem(item).startNode.time | datetimeUTC }} • {{ taxiItem(item).startNode.name }}
                    </span>
                    <v-icon class="mx-2" small>mdi-arrow-right</v-icon>
                    <span class="font-weight-medium">
                      {{ taxiItem(item).endNode.time | datetimeUTC }} • {{ taxiItem(item).endNode.name }}
                    </span>
                  </div>

                  <div v-else-if="item.type === 'railpass'">
                    <span class="font-weight-medium">{{ item.name }}</span>
                  </div>

                  <div v-else-if="item.type === 'distribution'">
                    <span class="font-weight-medium mr-2"
                      >{{ item.title || item.slug }} • {{ item.minDeliveryDays }} -
                      {{ item.maxDeliveryDays }} days</span
                    >
                  </div>

                  <div v-else-if="item.type === 'article'">
                    <span class="font-weight-medium">{{ item.title }}</span>
                  </div>

                  <div v-else-if="item.type === 'giftcard'">
                    <span class="font-weight-medium">{{ item.name }}</span>
                  </div>
                </template>

                <template v-slot:[`item.price`]="{ item }">
                  <div class="font-weight-bold">
                    <v-chip color="warning" class="text-capitalize priceValue" label small dark style="width: 100%">
                      {{ item.price.plainText }}
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.refundValue`]="{ item }">
                  <div class="font-weight-bold" v-if="item.cancelInformation.amount.amount">
                    <v-chip color="danger" class="text-capitalize priceValue" label small dark style="width: 100%">
                      {{ item.cancelInformation.amount.plainText }}
                    </v-chip>
                  </div>
                </template>

                <template v-slot:[`item.itemRefundAmount`]="{ item }">
                  <v-text-field
                    v-if="item.type != 'journey'"
                    v-model="item.itemRefundAmount"
                    :suffix="item.price.currency"
                    label="Amount"
                    type="number"
                    solo
                    hide-details
                    dense
                    outlined />
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-textarea
                v-model="specialRefundDataForm.description"
                outlined
                no-resize
                rows="6"
                :rules="[rules.required]"
                label="Description"
                clearable />
            </v-col>
            <v-col cols="12" md="6">
              <h3 class="mb-6">Total Refund Amount</h3>
              <v-text-field
                label="Amount"
                :suffix="currency"
                type="number"
                v-model="specialRefundDataForm.totalRefundAmount"
                outlined />

              <v-tooltip :disabled="isRefundActive" color="black" bottom>
                <template v-slot:activator="{ on }">
                  <div style="display: inline-block" v-on="on">
                    <v-btn @click="refundSpecial" :loading="loading" :disabled="!isRefundActive" class="primary"
                      >REFUND
                    </v-btn>
                  </div>
                </template>
                <span>Please enter a description and total refund amount to continue.</span>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Trainplanet from "@/util/trainplanet.api";
import moment from "moment";

export default {
  name: "SpecialRefund",
  props: {
    bookings: Array,
    isVisible: {
      type: Boolean,
      default: false,
    },
    printedItemIds: {
      required: false,
      type: Array,
    },
    items: {
      type: Array,
      default: [],
    },
    orderId: {
      required: false,
      default: null,
    },
    currency: {
      type: String,
      default: "SEK",
    },
    taxiItem: {
      type: Function,
      default: () => {},
    },
    getTaxiName: {
      type: Function,
      default: () => {},
    },
  },
  props: {
    bookings: Array,
    isVisible: {
      type: Boolean,
      default: false,
    },
    printedItemIds: {
      required: false,
      type: Array,
    },
    items: {
      type: Array,
      default: [],
    },
    orderId: {
      required: false,
      default: null,
    },
    currency: {
      type: String,
      default: "SEK",
    },
    taxiItem: {
      type: Function,
      default: () => {},
    },
    getTaxiName: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      rules: {
        required: (value) => !!value || "Field is required",
      },

      selectedItemType: null,
      title: "Refund Items with Special Reason",
      selectedItemsIDs: [],
      orderItemHeaders: [
        {
          text: "Type",
          value: "type",
          sortable: false,
          width: "1%", // auto width
        },
        {
          text: "Status",
          value: "status",
          sortable: false,
          width: "1%", // auto width
        },
        {
          text: "Item",
          value: "itemdata",
          sortable: false,
          width: 400,
          width: 400,
        },
        {
          text: "Price",
          value: "price",
          align: "left",
          sortable: false,
        },
        {
          text: "Refunded Total",
          value: "refundValue",
          align: "left",
          sortable: false,
        },
        {
          text: "Refund Amount ",
          value: "itemRefundAmount",
          align: "left",
          sortable: false,
          width: 150,
        },
      ],
      specialRefundDataForm: {
        totalRefundAmount: null,
        description: "",
        items: [],
      },
      loading: false,
      isDialogResetable: false,
      syncedAmounts: {},
    };
  },

  filters: {
    datetime(datetime) {
      return moment(datetime).format("MMM DD HH:mm");
    },
    datetimeUTC(datetime) {
      return moment.parseZone(datetime).format("MMM DD HH:mm");
    },
    secondsToMinutes(seconds) {
      return (seconds / 60).toFixed(0) + " minutes";
    },
    metersToKilometers(meters) {
      return (meters / 1000).toFixed(1) + " km";
    },
  },
  computed: {
    dialogVisible: {
      get() {
        setTimeout(() => {
          this.selectedItemType = null;
        }, 300);
        return this.isVisible;
      },
      set(value) {
        this.$emit("update:isVisible", value);
      },
    },
    isRefundActive() {
      return (
        this.specialRefundDataForm.description !== "" &&
        this.specialRefundDataForm.description !== null &&
        this.specialRefundDataForm.totalRefundAmount !== null &&
        this.specialRefundDataForm.totalRefundAmount !== ""
      );
    },
    dataItems() {
      let items = [];

      this.items.forEach((item) => {
        if (item.type === "journey") {
          items.push(item);
        } else if (
          ["railpass", "article", "taxi"].includes(item.type) ||
          (item.type === "distribution" && item.category == "physical") ||
          (item.type === "giftcard" && item.purpose == "purchasing")
        ) {
          if (item.isPaid) {
            items.push(item);
          }
        }
      });

      items.forEach((item) => {
        this.returnDisabledStatus(item);
      });

      return items;
    },
  },
  watch: {
    dialogVisible(status) {
      if (status) {
        if (this.isDialogResetable) {
          this.$refs[`refundForm`].reset();
        }
        this.isDialogResetable = true;
        this.specialRefundDataForm.totalRefundAmount = null;
        this.specialRefundDataForm.description = "";
      }
    },
    dataItems: {
      handler(newVal) {
        if (newVal.length) {
          newVal.forEach((item) => {
            if (item.type !== "journey") return;
            item.itinerary.forEach((trip) => {
              trip.passengers.forEach((passenger) => {
                const key = this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger);
                this.$set(this.syncedAmounts, key, passenger.itemRefundAmount);
              });
            });
          });
        }
      },
      deep: true,
    },
  },
  methods: {
    getStatusColor(status) {
      switch (status) {
        case "pending":
          return "amber";
        case "completed":
          return "green";
        case "cancelled":
          return "danger";
        case "partially_cancelled":
          return "warning";
        case "partially_credited":
          return "warning";
        case "partially_refunded":
          return "warning";
        case "refunded":
          return "warning";
        case "credited":
          return "warning";
        default:
          return "grey";
      }
    },
    getSyncedAmount(passenger) {
      const key = this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger);
      return this.syncedAmounts[key] || passenger.itemRefundAmount;
    },
    updateSyncedAmount(passenger, val) {
      const key = this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger);
      this.$set(this.syncedAmounts, key, val);

      this.dataItems.forEach((item) => {
        if (item.type !== "journey") return;
        item.itinerary.forEach((trip) => {
          trip.passengers.forEach((p) => {
            if (this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(p) === key) {
              p.itemRefundAmount = val;
            }
          });
        });
      });
    },
    isSynced(passenger) {
      const key = this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger);
      let count = 0;
      this.dataItems.forEach((item) => {
        if (item.type !== "journey") return;
        item.itinerary.forEach((trip) => {
          trip.passengers.forEach((p) => {
            if (this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(p) === key) count++;
          });
        });
      });
      return count > 1;
    },
    closeSpecialRefundModal() {
      this.$emit("update:isVisible", false);
    },
    getTypeColor(type) {
      switch (type) {
        case "journey":
          return "#494968";
        case "railpass":
          return "#E7A813";
        case "giftcard":
          return "#70A37F";
        case "distribution":
          return "#8692c5";
        case "article":
          return "#5d91e8";
        case "taxi":
          return "#2b2b2b";
        default:
          return "secondary";
      }
    },
    isItemPrinted(itemId) {
      return this.printedItemIds && this.printedItemIds.includes(itemId);
    },
    itineraryIsNrt(itinerary) {
      // if only contains date information and not time information (e.g. "2022-02-02")
      return itinerary.departure.datetime.length <= 10 && itinerary.arrival.datetime.length <= 10;
    },
    getTripPassengerSeatDetailsString(passenger) {
      const string = [];

      if (passenger.comfort && passenger.comfort.name) string.push(passenger.comfort.name);
      if (passenger.compartment && passenger.compartment.name) string.push(passenger.compartment.name);
      if (passenger.orientation && passenger.orientation.name) string.push(passenger.orientation.name);
      if (passenger.direction && passenger.direction.name) string.push(passenger.direction.name);

      return string.join(" • ");
    },
    getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger) {
      const ret = [];

      if (passenger.externalIds) {
        const booking = this.bookings.find((booking) => booking.id === passenger.externalBookingId);

        if (booking) {
          for (const key in passenger.externalIds) {
            const itemId = passenger.externalIds[key];

            ret.push(booking.externalOrderId + itemId.toString().padStart(4, "0")); // e.g. RZF3982T0011
          }
        }
      }

      return ret.join(",");
    },
    getPassengerStatusColor(status) {
      switch (status) {
        case "cancelled":
          return "danger";
        case "credited":
          return "green";
        case "refunded":
          return "danger";
        default:
          return "grey";
      }
    },
    returnDisabledStatus(item) {
      if (item.itinerary) {
        item.itinerary.forEach((element) => {
          let insertedIDs = [];
          element.passengers.forEach((passenger) => {
            const passengerId = this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger);
            if (!insertedIDs.includes(passengerId)) {
              insertedIDs.push(passengerId);
              passenger.statusDisability = true;
            } else {
              passenger.statusDisability = false;
            }
          });
        });
      }
    },
    async refundSpecial() {
      const confirmed = await this.$confirm(
        `<h4>Are you sure you want to refund the total amount of ${this.specialRefundDataForm.totalRefundAmount} ${this.currency} for a special reason? This action cannot be undone.</h4>`,
        {
          color: "primary",
          title: "Refund Items with Special Reason",
        }
      );
      if (confirmed && this.$refs[`refundForm`].validate()) {
        try {
          this.loading = true;
          this.specialRefundDataForm.totalRefundAmount = parseFloat(this.specialRefundDataForm.totalRefundAmount);
          this.specialRefundDataForm.items = [];

          const uniqueItems = new Set();

          this.items.forEach((item) => {
            let externalIds = [];
            let externalBookingId = "";
            if (item.type == "journey") {
              item.itinerary.forEach((itinerary) => {
                itinerary["passengers"].forEach((passenger) => {
                  externalIds = passenger["externalIds"];
                  if (passenger.itemRefundAmount) {
                    externalBookingId = passenger.externalBookingId;
                    let obj = {
                      itemRefundAmount: parseFloat(passenger.itemRefundAmount),
                      itemId: item.id,
                      bookingId: externalBookingId,
                      externalIds: externalIds,
                    };
                    const uniqueKey = `${obj.itemId}-${this.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger)}`;

                    if (!uniqueItems.has(uniqueKey)) {
                      uniqueItems.add(uniqueKey);
                      this.specialRefundDataForm.items.push(obj);
                    }
                  }
                });
              });
            } else {
              if (item.itemRefundAmount) {
                let obj = {
                  itemRefundAmount: parseFloat(item.itemRefundAmount),
                  itemId: item.id,
                };
                const uniqueKey = `${obj.itemId}-${JSON.stringify(obj.externalIds)}`;
                if (!uniqueItems.has(uniqueKey)) {
                  uniqueItems.add(uniqueKey);
                  this.specialRefundDataForm.items.push(obj);
                }
              }
            }
          });

          await Trainplanet.manualRefund(this.orderId, this.specialRefundDataForm);

          await this.$store.dispatch("success", "Order has been refunded successfully.");
          this.closeSpecialRefundModal();
          this.$emit("itemRefund");
        } catch (error) {
          await this.$store.dispatch("error", error.response.data.message);
        } finally {
          this.loading = false;
        }
      }
    },
  },
};
</script>

<style scoped lang="scss">
.name {
  display: flex;
  align-items: center;
  gap: 8px;
  min-width: 200px;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.taxi-text {
  color: #fec33a;
}
</style>
