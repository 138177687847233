<template>
  <div>
    <h3 class="mt-3">Select the articles you want to add to the order.</h3>
    <span>You may only select the articles provided by tenant which is registered in order. </span>
    <v-form ref="articleForm" v-model="form.valid" :loading="isLoading" :disabled="isLoading">
      <v-row class="mt-3">
        <v-col cols="12">
          <v-combobox
            v-if="articles.length > 0"
            label="Articles"
            v-model="form.articles"
            :items="articles"
            item-text="title"
            item-value="slug"
            small-chips
            hide-details
            multiple
            outlined>
            <template v-slot:selection="{ attrs, item, parent, selected }">
              <v-chip v-if="item === Object(item)" v-bind="attrs" :input-value="selected" label small>
                <span class="pr-2">
                  {{ item.title }}
                </span>
                <v-icon small color="primary" @click="parent.selectItem(item)"> $delete </v-icon>
              </v-chip>
            </template>
            <template v-slot:append-outer>
              <v-tooltip bottom color="black">
                <template v-slot:activator="{ on }">
                  <v-icon v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <span
                  >Add additional articles to the order. For flexible priced articles, you can leave the price boxes
                  below empty and the system will choose the correct price depending on if the order is international or
                  domestic.</span
                >
              </v-tooltip>
            </template>
          </v-combobox>
        </v-col>
        <v-col cols="11">
          <v-list v-if="form.articles.length > 0">
            <v-list-item
              :class="'d-flex align-center py-1 px-3 rounded border999 ' + (i < 1 ? 'mt-2' : 'mt-4')"
              v-for="(item, i) in form.articles"
              :key="i">
              <v-list-item-title v-text="item.title"></v-list-item-title>
              <b v-if="!item.flexiblePrice" style="font-size: 20px; width: 100px">
                {{ String(item.prices.find((r) => r.currency === currency)?.amount) }}
                {{ currency }}
              </b>

              <v-text-field
                v-if="item.flexiblePrice"
                :disabled="!item.flexiblePrice"
                hide-details
                :label="
                  String(
                    item.prices.find(
                      (r) =>
                        r.currency === currency &&
                        (r.exclusiveTo === (isInternational ? 'international' : 'domestic') || !r.exclusiveTo)
                    )?.amount
                  )
                "
                v-model.number="item.setPrice"
                hint="Price"
                outlined
                class="font-weight-bold"
                type="number"
                dense></v-text-field>
              <span
                v-if="item.flexiblePrice"
                style="position: absolute; right: 30px; top: 50%; transform: translateY(-50%)"
                class="currency">
                <b style="font-size: 16px">{{ currency }}</b>
              </span>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-form>

    <v-btn
      :loading="isLoading"
      :disabled="!isValid"
      class="mt-3"
      color="accent"
      style="margin-left: 390px"
      @click="saveArticles()">
      Save
    </v-btn>
  </div>
</template>
<script>
import { validator } from "@/util/helpers";
import Trainplanet from "@/util/trainplanet.api.js";

export default {
  name: "ArticleForm",
  data() {
    return {
      isValid: false,
      isLoading: false,
      form: {
        valid: false,
        articles: [],
      },
      articles: [],
      validate: {
        greater: validator.greaterZeroCheck(),
      },
    };
  },
  props: {
    tenantId: {
      type: String,
      default: "",
      required: true,
    },
    currency: {
      type: String,
      default: "",
      required: true,
    },
    registeredArticles: {
      type: Array,
      default: () => [],
    },
    orderId: {
      type: String,
      default: "",
      required: true,
    },
    locale: {
      type: String,
      default: "",
    },
    isInternational: {
      type: Boolean,
      required: false,
    },
  },
  mounted() {
    this.getArticles();
  },
  watch: {
    "form.articles": {
      handler(newValue) {
        this.isValid = newValue.length > 0;
      },
      deep: true,
    },
  },
  methods: {
    async saveArticles() {
      try {
        if (this.$refs[`articleForm`].validate()) {
          this.isLoading = true;

          const payload = {
            articles: this.form.articles.map((item) => {
              const priceObject = item.prices.find(
                (x) =>
                  x.currency === this.currency &&
                  (x.exclusiveTo === (this.isInternational ? "international" : "domestic") || !x.exclusiveTo)
              );
              return {
                slug: item.slug,
                price: item.setPrice ? { amount: item.setPrice } : priceObject,
              };
            }),
          };

          await Trainplanet.addItems(this.orderId, payload);

          this.$emit("update:isVisible", false);
          this.$emit("itemAddedOrRemove");
          await this.$store.dispatch("success", "You have successfully added distribution!");
          this.isLoading = false;
        }
      } catch (error) {
        await this.$store.dispatch("error", error.response.data.message || error);
        this.isLoading = false;
      }
    },
    async getArticles() {
      this.isLoading = true;
      const res = await Trainplanet.listOrderSystemArticles({ tenantId: this.tenantId });
      this.isLoading = false;
      if (!res.length) {
        return;
      }
      this.articles = res.map((article) => {
        const response = article;
        let translation = response.translations.find((x) => x.locale === "en-SE");

        if (this.locale) {
          const hasTranslation = response.translations.find((x) => x.locale === this.locale);
          //title could be empty, use fallback if so
          if (hasTranslation && hasTranslation.title) {
            translation = hasTranslation;
          }
        }

        const priceObject = response.prices.find(
          (x) =>
            x.currency === this.currency &&
            (x.exclusiveTo === (this.isInternational ? "international" : "domestic") || !x.exclusiveTo)
        );

        if (response.flexiblePrice && !priceObject) {
          response.prices.push({
            currency: this.currency,
            vat: 0,
            amount: 0,
          });
        }

        if (translation && priceObject) {
          response.title = `${translation.title} (+${priceObject.amount} ${priceObject.currency})`;
        } else {
          response.title = `${translation.title}`;
        }
        const isRegistered = this.registeredArticles.find((x) => x.id === response.id);
        if (isRegistered) {
          return;
        }

        return response;
      });
    },
  },
};
</script>
