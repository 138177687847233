var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-row', {
    attrs: {
      "justify": "center"
    }
  }, [_c('v-dialog', {
    staticStyle: {
      "overflow-x": "auto"
    },
    attrs: {
      "max-width": "1400"
    },
    model: {
      value: _vm.dialogVisible,
      callback: function ($$v) {
        _vm.dialogVisible = $$v;
      },
      expression: "dialogVisible"
    }
  }, [_c('v-card', [_c('v-toolbar', {
    staticClass: "v-sheet theme--dark v-toolbar secondary"
  }, [_c('v-toolbar-title', [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "icon": "",
      "dark": ""
    },
    on: {
      "click": function ($event) {
        return _vm.closeSpecialRefundModal();
      }
    }
  }, [_c('v-icon', [_vm._v("mdi-close")])], 1)], 1), _c('v-form', {
    ref: "refundForm",
    staticClass: "pa-4"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-data-table', {
    staticClass: "elevation-1 mt-3",
    attrs: {
      "headers": _vm.orderItemHeaders,
      "items": _vm.dataItems,
      "item-key": "id",
      "show-expand": "",
      "disable-pagination": "",
      "hide-default-footer": ""
    },
    scopedSlots: _vm._u([{
      key: `expanded-item`,
      fn: function (_ref) {
        var headers = _ref.headers,
          item = _ref.item;
        return [item.type === 'journey' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-timeline', {
          attrs: {
            "align-top": "",
            "dense": ""
          }
        }, _vm._l(item.itinerary, function (trip, i) {
          return _c('v-timeline-item', {
            key: i,
            attrs: {
              "small": ""
            }
          }, [_c('v-card', {
            attrs: {
              "color": _vm.itineraryIsNrt(trip) ? 'primary lighten-4' : 'grey lighten-4'
            }
          }, [_c('v-card-title', {
            staticClass: "text-subtitle-2"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("datetime")(trip.departure.datetime)) + " • " + _vm._s(trip.departure.locationName))]), _c('v-icon', {
            staticStyle: {
              "margin": "0 10px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-arrow-right")]), _c('span', [_vm._v(_vm._s(_vm._f("datetime")(trip.arrival.datetime)) + " • " + _vm._s(trip.arrival.locationName))]), _c('v-spacer'), _vm.itineraryIsNrt(trip) ? _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v("NRT Fare")]) : _vm._e()], 1), _c('v-card-subtitle', [_c('span', [_vm._v(_vm._s(trip.operator.name) + " • " + _vm._s(trip.product.name))]), trip.vehicle.name || trip.transportId ? _c('span', [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward ")]), _vm._v(_vm._s(trip.vehicle.name) + " " + _vm._s(trip.transportId) + " ")], 1) : _vm._e()]), _c('v-card-text', [_c('div', {
            staticClass: "text--primary"
          }, [_c('v-list', _vm._l(trip.passengers, function (passenger, i) {
            return _c('v-list-item', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: item.isPaid && ['journey', 'railpass', 'article'].includes(item.type) || item.type == 'distribution' && item.category == 'physical' || item.type == 'giftcard' && item.purpose == 'purchasing',
                expression: "\n                                  (item.isPaid && ['journey', 'railpass', 'article'].includes(item.type)) ||\n                                  (item.type == 'distribution' && item.category == 'physical') ||\n                                  (item.type == 'giftcard' && item.purpose == 'purchasing')\n                                "
              }],
              key: i
            }, [_c('v-list-item-title', {
              staticClass: "d-flex align-center",
              staticStyle: {
                "gap": "2rem"
              }
            }, [_c('div', {
              staticClass: "d-inline"
            }, [passenger.personalInfo ? _c('span', [_vm._v(" " + _vm._s(passenger.personalInfo.firstName) + " " + _vm._s(passenger.personalInfo.lastName) + " ")]) : _vm._e()]), _c('div', {
              staticClass: "d-inline"
            }, [_c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-seat-recline-normal")]), _vm._v(" " + _vm._s(passenger.seatNumber || "-") + " ")], 1), _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-train-car-passenger")]), _vm._v(" " + _vm._s(passenger.carriageNumber || "-") + " ")], 1), passenger.ageGroup && passenger.ageGroup.name ? _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-slash-forward")]), _vm._v(" " + _vm._s(passenger.ageGroup.name) + " ")], 1) : _vm._e(), passenger.comfort ? _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-slash-forward")]), _vm._v(" " + _vm._s(_vm.getTripPassengerSeatDetailsString(passenger)) + " ")], 1) : _vm._e()])]), _c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: passenger.statusDisability,
                expression: "passenger.statusDisability"
              }],
              staticClass: "font-weight-bold"
            }, [_c('v-chip', {
              staticClass: "text-capitalize priceValue",
              staticStyle: {
                "margin": "0 10px"
              },
              attrs: {
                "color": "warning",
                "label": "",
                "small": "",
                "dark": ""
              }
            }, [_vm._v(" " + _vm._s(passenger.price.plainText) + " ")])], 1), _c('div', {
              staticClass: "d-flex align-center"
            }, [_c('div', {
              staticClass: "status"
            }, [passenger.status !== 'pending' && passenger.status !== 'completed' ? _c('v-chip', {
              staticClass: "text-uppercase",
              staticStyle: {
                "width": "160px"
              },
              style: {
                color: ['cancelled', 'refunded'].includes(passenger.status) ? 'white' : 'auto'
              },
              attrs: {
                "label": "",
                "small": "",
                "color": _vm.getPassengerStatusColor(passenger.status)
              }
            }, [_vm._v(" " + _vm._s(passenger.status) + ": " + _vm._s(passenger.cancelInformation.amount.plainText) + " ")]) : _vm._e()], 1), _c('div', [_c('span', {
              staticClass: "pr-4 pl-2",
              staticStyle: {
                "margin-right": "13px"
              }
            }, [_c('span', [_vm._v(" " + _vm._s(_vm.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger)) + " ")])])]), _c('div', {
              staticClass: "d-flex",
              staticStyle: {
                "width": "120px"
              }
            }, [_c('v-tooltip', {
              attrs: {
                "disabled": !_vm.isSynced(passenger),
                "max-width": "500",
                "color": "black",
                "top": ""
              },
              scopedSlots: _vm._u([{
                key: "activator",
                fn: function (_ref2) {
                  var on = _ref2.on,
                    attrs = _ref2.attrs;
                  return [_c('v-text-field', _vm._g(_vm._b({
                    directives: [{
                      name: "show",
                      rawName: "v-show",
                      value: passenger.statusDisability,
                      expression: "passenger.statusDisability"
                    }],
                    attrs: {
                      "value": _vm.getSyncedAmount(passenger),
                      "suffix": passenger.cancelInformation.amount.currency,
                      "label": "Amount",
                      "type": "number",
                      "solo": "",
                      "hide-details": "",
                      "dense": "",
                      "outlined": ""
                    },
                    on: {
                      "input": function ($event) {
                        return _vm.updateSyncedAmount(passenger, $event);
                      }
                    }
                  }, 'v-text-field', attrs, false), on))];
                }
              }], null, true)
            }, [_c('span', [_vm._v(" There are multiple passengers with the external row ID of "), _c('b', [_vm._v(_vm._s(_vm.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger)))]), _vm._v(" on different itineraries. For these passengers, the input fields will be automatically filled. ")])])], 1)])], 1);
          }), 1)], 1)])], 1)], 1);
        }), 1)], 1) : item.type === 'taxi' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-timeline', {
          attrs: {
            "align-top": "",
            "dense": ""
          }
        }, [_c('v-timeline-item', {
          attrs: {
            "small": ""
          }
        }, [_c('v-card', {
          attrs: {
            "color": "grey lighten-4"
          }
        }, [_c('v-card-title', {
          staticClass: "text-subtitle-2"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).startNode.time)) + " • " + _vm._s(_vm.taxiItem(item).startNode.name))]), _c('v-icon', {
          staticStyle: {
            "margin": "0 10px"
          },
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', [_vm._v(_vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).endNode.time)) + " • " + _vm._s(_vm.taxiItem(item).endNode.name))])], 1), _c('v-card-subtitle', [_c('span', [_vm._v(_vm._s(_vm.getTaxiName(_vm.taxiItem(item))) + " • " + _vm._s(_vm._f("metersToKilometers")(_vm.taxiItem(item).distanceMeters)) + " • " + _vm._s(_vm._f("secondsToMinutes")(_vm.taxiItem(item).durationSeconds)))])]), _c('v-card-text', [_c('div', {
          staticClass: "text--primary"
        }, [_c('v-list', _vm._l(_vm.taxiItem(item).passengers, function (passenger, i) {
          return _c('v-list-item', {
            key: i
          }, [_c('v-list-item-title', [_c('div', {
            staticClass: "d-flex align-center"
          }, [_c('div', {
            staticClass: "name",
            staticStyle: {
              "min-width": "300px"
            }
          }, [_c('span', [_vm._v(" " + _vm._s(passenger.firstName) + " " + _vm._s(passenger.lastName) + " ")]), passenger.isContact ? _c('v-chip', {
            attrs: {
              "small": "",
              "color": "primary"
            }
          }, [_vm._v(" Contact Person ")]) : _vm._e()], 1), _c('span', {
            staticClass: "d-flex align-center"
          }, [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-phone")]), _vm._v(" " + _vm._s(passenger.phone || "-") + " ")], 1), _c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward")]), _c('span', {
            staticClass: "d-flex align-center"
          }, [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-email")]), _vm._v(" " + _vm._s(passenger.email || "-") + " ")], 1), _c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward")]), _c('span', {
            staticClass: "d-flex align-center"
          }, [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-cake-variant")]), _vm._v(" " + _vm._s(passenger.birthdate || "-") + " ")], 1)], 1)])], 1);
        }), 1)], 1), _vm.taxiItem(item).startNode.description ? _c('v-card', {
          staticClass: "d-flex align-start mt-5 pa-3 font-weight-bold",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-information")]), _c('div', {
          staticClass: "grey--text text--darken-2"
        }, [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.taxiItem(item).startNode.description)
          }
        })])], 1) : _vm._e(), _vm.taxiItem(item).infoToDriver ? _c('v-card', {
          staticClass: "d-flex align-start mt-5 pa-3 font-weight-bold",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "blue-grey"
          }
        }, [_vm._v("mdi-note-text-outline")]), _c('span', {
          staticClass: "font-weight-bold mr-1"
        }, [_vm._v("Note for the driver: ")]), _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v(" " + _vm._s(_vm.taxiItem(item).infoToDriver))])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : item.type === 'article' || item.type === 'distribution' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [item.description ? _c('div', {
          staticClass: "my-5 secondary--text"
        }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e(), item.tickets && item.tickets.length > 0 ? _c('v-container', [_c('v-simple-table', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left"
              }, [_vm._v("Box Number")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v("Paper Number")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v("Used Date")])])]), _c('tbody', _vm._l(item.tickets, function (ticket) {
                return _c('tr', {
                  key: ticket.paperNumber
                }, [_c('td', [_vm._v(_vm._s(ticket.boxNumber))]), _c('td', [_vm._v(_vm._s(ticket.paperNumber))]), _c('td', [_vm._v(_vm._s(ticket.usedDate))])]);
              }), 0)];
            },
            proxy: true
          }], null, true)
        })], 1) : _vm._e()], 1) : _vm._e(), item.type === 'railpass' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "py-4"
        }, [_c('v-simple-table', {
          staticClass: "elevation-1",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Comfort")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Age Group")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Passenger")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Start Date")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Stop Date")])])]), _c('tbody', [_c('tr', {
                key: item.name
              }, [_c('td', [_vm._v(_vm._s(item.comfort.name))]), _c('td', [_vm._v(_vm._s(item.ageGroup.name))]), _c('td', [_vm._v(" " + _vm._s(item.passenger.firstName) + " " + _vm._s(item.passenger.lastName) + " ")]), _c('td', [_vm._v(_vm._s(item.startDate))]), _c('td', [_vm._v(_vm._s(item.stopDate))])])])];
            },
            proxy: true
          }], null, true)
        })], 1)]) : _vm._e(), item.type === 'giftcard' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "py-4"
        }, [_c('v-simple-table', {
          staticClass: "elevation-1",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Purpose")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Code")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Expiry date")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Remaining Amount")])])]), _c('tbody', [_c('tr', {
                key: item.name
              }, [_c('td', [_vm._v(_vm._s(item.purpose))]), _c('td', [_vm._v(_vm._s(item.giftCard.code))]), _c('td', [_vm._v(_vm._s(item.giftCard.expires))]), _c('td', [_vm._v(_vm._s(item.giftCard.remainingAmount))])])])];
            },
            proxy: true
          }], null, true)
        })], 1)]) : _vm._e()];
      }
    }, {
      key: `item.type`,
      fn: function (_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-chip', {
          staticClass: "text-capitalize font-weight-medium",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "color": _vm.getTypeColor(item.type),
            "label": "",
            "small": "",
            "dark": ""
          }
        }, [_c('span', {
          class: item.type === 'taxi' ? 'taxi-text' : ''
        }, [_vm._v(" " + _vm._s(item.type) + " ")])]), _vm.isItemPrinted(item.id) ? _c('span', {
          staticClass: "ml-2"
        }, [_c('v-icon', [_vm._v("mdi-printer")])], 1) : _vm._e(), item.isInterrailSeatReservation && item.type === 'journey' && item.type === 'railpass' ? _c('span', {
          staticClass: "ml-2 d-flex align-center"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/interrail-logo.png"),
            "width": "20",
            "height": "18",
            "alt": ""
          }
        })]) : _vm._e()], 1)];
      }
    }, {
      key: `item.status`,
      fn: function (_ref4) {
        var item = _ref4.item;
        return [item.status ? _c('v-chip', {
          staticClass: "text-capitalize",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "color": _vm.getStatusColor(item.status),
            "label": "",
            "small": "",
            "outlined": ""
          }
        }, [_vm._v(" " + _vm._s(item.status.replace("_", " ")) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.itemdata`,
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.type === 'journey' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm._f("datetime")(item.departure.datetime)) + " • " + _vm._s(item.departure.locationName))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm._f("datetime")(item.arrival.datetime)) + " • " + _vm._s(item.arrival.locationName))])], 1) : item.type === 'taxi' ? _c('div', {
          staticStyle: {
            "text-wrap": "nowrap"
          }
        }, [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).startNode.time)) + " • " + _vm._s(_vm.taxiItem(item).startNode.name) + " ")]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).endNode.time)) + " • " + _vm._s(_vm.taxiItem(item).endNode.name) + " ")])], 1) : item.type === 'railpass' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.name))])]) : item.type === 'distribution' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium mr-2"
        }, [_vm._v(_vm._s(item.title || item.slug) + " • " + _vm._s(item.minDeliveryDays) + " - " + _vm._s(item.maxDeliveryDays) + " days")])]) : item.type === 'article' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.title))])]) : item.type === 'giftcard' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.name))])]) : _vm._e()];
      }
    }, {
      key: `item.price`,
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "font-weight-bold"
        }, [_c('v-chip', {
          staticClass: "text-capitalize priceValue",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "color": "warning",
            "label": "",
            "small": "",
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(item.price.plainText) + " ")])], 1)];
      }
    }, {
      key: `item.refundValue`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [item.cancelInformation.amount.amount ? _c('div', {
          staticClass: "font-weight-bold"
        }, [_c('v-chip', {
          staticClass: "text-capitalize priceValue",
          staticStyle: {
            "width": "100%"
          },
          attrs: {
            "color": "danger",
            "label": "",
            "small": "",
            "dark": ""
          }
        }, [_vm._v(" " + _vm._s(item.cancelInformation.amount.plainText) + " ")])], 1) : _vm._e()];
      }
    }, {
      key: `item.itemRefundAmount`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [item.type != 'journey' ? _c('v-text-field', {
          attrs: {
            "suffix": item.price.currency,
            "label": "Amount",
            "type": "number",
            "solo": "",
            "hide-details": "",
            "dense": "",
            "outlined": ""
          },
          model: {
            value: item.itemRefundAmount,
            callback: function ($$v) {
              _vm.$set(item, "itemRefundAmount", $$v);
            },
            expression: "item.itemRefundAmount"
          }
        }) : _vm._e()];
      }
    }], null, true)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('v-textarea', {
    attrs: {
      "outlined": "",
      "no-resize": "",
      "rows": "6",
      "rules": [_vm.rules.required],
      "label": "Description",
      "clearable": ""
    },
    model: {
      value: _vm.specialRefundDataForm.description,
      callback: function ($$v) {
        _vm.$set(_vm.specialRefundDataForm, "description", $$v);
      },
      expression: "specialRefundDataForm.description"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('h3', {
    staticClass: "mb-6"
  }, [_vm._v("Total Refund Amount")]), _c('v-text-field', {
    attrs: {
      "label": "Amount",
      "suffix": _vm.currency,
      "type": "number",
      "outlined": ""
    },
    model: {
      value: _vm.specialRefundDataForm.totalRefundAmount,
      callback: function ($$v) {
        _vm.$set(_vm.specialRefundDataForm, "totalRefundAmount", $$v);
      },
      expression: "specialRefundDataForm.totalRefundAmount"
    }
  }), _c('v-tooltip', {
    attrs: {
      "disabled": _vm.isRefundActive,
      "color": "black",
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref9) {
        var on = _ref9.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "inline-block"
          }
        }, on), [_c('v-btn', {
          staticClass: "primary",
          attrs: {
            "loading": _vm.loading,
            "disabled": !_vm.isRefundActive
          },
          on: {
            "click": _vm.refundSpecial
          }
        }, [_vm._v("REFUND ")])], 1)];
      }
    }])
  }, [_c('span', [_vm._v("Please enter a description and total refund amount to continue.")])])], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }