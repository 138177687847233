var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('header', {
    staticClass: "theme--light v-navigation-drawer elevation-1",
    staticStyle: {
      "height": "50px",
      "border-radius": "5px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__content",
    staticStyle: {
      "height": "50px",
      "padding": "17px"
    }
  }, [_c('div', {
    staticClass: "v-toolbar__title d-flex justify-space-between align-center",
    staticStyle: {
      "width": "100%"
    }
  }, [_c('div', {
    staticClass: "d-flex py-5"
  }, [_c('h4', {
    staticClass: "black--text d-flex justify-center align-center"
  }, [_c('span', {
    staticClass: "mango-red mr-1"
  }, [_vm._v("Items ")]), _vm._v("(" + _vm._s(_vm.items.length) + ") ")]), _vm.orderId && !_vm.orderFullyPaid && _vm.canUseGiftcard && !_vm.isGiftCardInItems ? _c('div', {
    staticClass: "gift-card"
  }, [_c('v-text-field', {
    staticClass: "gift-card-input",
    attrs: {
      "label": "Gift Card Code",
      "height": "30px",
      "hide-details": "",
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.giftCardCode,
      callback: function ($$v) {
        _vm.giftCardCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "giftCardCode"
    }
  }), _c('v-btn', {
    attrs: {
      "disabled": !_vm.giftCardCode,
      "loading": _vm.loading,
      "height": "auto",
      "color": "accent"
    },
    on: {
      "click": _vm.useGiftCardCode
    }
  }, [_vm._v(" Use Gift Card ")])], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex"
  }, [!_vm.showSelect.value && _vm.$route.name == 'orders-edit' ? _c('v-tooltip', {
    attrs: {
      "content-class": "tooltip black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "flex"
          }
        }, on), [_vm.showAddItemButton && !_vm.showSelect.value ? _c('v-btn', {
          staticClass: "success white--text mr-2",
          attrs: {
            "loading": _vm.loading,
            "small": ""
          },
          on: {
            "click": _vm.handleAddItemClick
          }
        }, [_vm._v(" Add ")]) : _vm._e()], 1)];
      }
    }], null, false, 3243025489)
  }, [_vm._v(" Add item(s) to the order ")]) : _vm._e(), _vm.showRemoveButton && !_vm.showSelect.value ? _c('v-tooltip', {
    attrs: {
      "content-class": "tooltip black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref2) {
        var on = _ref2.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "flex"
          }
        }, on), [_c('v-btn', {
          staticClass: "indigo accent-2 white--text",
          attrs: {
            "disabled": !_vm.removeItems.length,
            "loading": _vm.loading,
            "small": ""
          },
          on: {
            "click": function ($event) {
              return _vm.setState('remove');
            }
          }
        }, [_c('span', [_vm._v("REMOVE")])])], 1)];
      }
    }], null, false, 1442207212)
  }, [_c('span', [_vm._v("Remove item(s) from the order")])]) : _vm._e(), !_vm.showSelect.value && _vm.$route.name == 'orders-edit' ? _c('div', {
    staticClass: "vertical-divider"
  }) : _vm._e(), !_vm.showSelect.value && _vm.$route.name == 'orders-edit' ? _c('v-tooltip', {
    attrs: {
      "content-class": "tooltip black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref3) {
        var on = _ref3.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "flex"
          }
        }, on), [!_vm.showSelect.value ? _c('v-btn', {
          staticClass: "white--text mr-2",
          attrs: {
            "loading": _vm.loading,
            "disabled": !_vm.orderIsPaid,
            "small": "",
            "color": "rgb(134, 146, 197)"
          },
          on: {
            "click": function ($event) {
              _vm.clickedButton = `item`;
              _vm.refundSpecialOrder({
                orderId: _vm.orderId
              });
            }
          }
        }, [_vm._v(" SPECIAL REFUND ")]) : _vm._e()], 1)];
      }
    }], null, false, 4139225974)
  }, [_vm._v(" Refund Items with Special Reason ")]) : _vm._e(), !_vm.showSelect.value && _vm.$route.name == 'orders-edit' ? _c('v-tooltip', {
    attrs: {
      "content-class": "tooltip black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref4) {
        var on = _ref4.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "flex"
          }
        }, on), [!_vm.showSelect.value ? _c('v-btn', {
          staticClass: "third white--text third mr-2",
          attrs: {
            "loading": _vm.loading,
            "disabled": !_vm.refundItems.length,
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.clickedButton = `item`;
              _vm.setState('refund');
            }
          }
        }, [_vm._v(" REFUND ")]) : _vm._e()], 1)];
      }
    }], null, false, 3326158877)
  }, [_vm._v(" Refund item(s) from the order ")]) : _vm._e(), !_vm.showSelect.value && _vm.$route.name == 'orders-edit' ? _c('v-tooltip', {
    attrs: {
      "content-class": "tooltip black",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref5) {
        var on = _ref5.on;
        return [_c('div', _vm._g({
          staticStyle: {
            "display": "flex"
          }
        }, on), [!_vm.showSelect.value ? _c('v-btn', {
          staticClass: "danger white--text",
          attrs: {
            "loading": _vm.loading,
            "disabled": !_vm.cancelItems.length,
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.clickedButton = `item_cancel`;
              _vm.setState('cancel');
            }
          }
        }, [_vm._v(" CANCEL ")]) : _vm._e()], 1)];
      }
    }], null, false, 2662504087)
  }, [_vm._v(" Cancel item(s) from the order ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_vm.showSelect.value ? _c('v-btn', {
    staticClass: "accent-2 white--text mr-2",
    class: _vm.dynamicStateActionColor,
    attrs: {
      "disabled": !_vm.selectedItems.length && !_vm.selectedChildItems.length,
      "loading": _vm.loading,
      "small": ""
    },
    on: {
      "click": _vm.handleAction
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.stateAction))])]) : _vm._e(), _vm.showSelect.value ? _c('v-btn', {
    staticClass: "indigo accent-2 white--text",
    attrs: {
      "small": ""
    },
    on: {
      "click": _vm.cancelSelection
    }
  }, [_c('span', [_vm._v("ABONDON ACTION")])]) : _vm._e()], 1)], 1)])])]), _c('Customer', {
    attrs: {
      "customer": _vm.customer,
      "inquiry-id": _vm.inquiryId,
      "order-id": _vm.orderId
    }
  }), _c('v-data-table', {
    staticClass: "elevation-1 mt-3 mb-5",
    attrs: {
      "headers": _vm.orderItemHeaders,
      "items": _vm.filteredItems,
      "item-key": "id",
      "show-expand": "",
      "disable-pagination": "",
      "hide-default-footer": "",
      "item-selectable": "selectable",
      "item-class": _vm.itemClass,
      "show-select": _vm.showSelect.value
    },
    scopedSlots: _vm._u([{
      key: `expanded-item`,
      fn: function (_ref6) {
        var headers = _ref6.headers,
          item = _ref6.item;
        return [item.type === 'journey' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-timeline', {
          attrs: {
            "align-top": "",
            "dense": ""
          }
        }, _vm._l(item.itinerary, function (trip, i) {
          return _c('v-timeline-item', {
            key: i,
            attrs: {
              "small": ""
            }
          }, [_c('v-card', {
            attrs: {
              "color": _vm.itineraryIsNrt(trip) ? 'primary lighten-4' : 'grey lighten-4'
            }
          }, [_c('v-card-title', {
            staticClass: "text-subtitle-2"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("datetime")(trip.departure.datetime)) + " • " + _vm._s(trip.departure.locationName))]), _c('v-icon', {
            staticStyle: {
              "margin": "0 10px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-arrow-right")]), _c('span', [_vm._v(_vm._s(_vm._f("datetime")(trip.arrival.datetime)) + " • " + _vm._s(trip.arrival.locationName))]), _c('v-spacer'), _vm.itineraryIsNrt(trip) ? _c('span', {
            staticClass: "font-weight-bold"
          }, [_vm._v("NRT Fare")]) : _vm._e()], 1), _c('v-card-subtitle', [_c('span', [_vm._v(_vm._s(trip.operator.name) + " • " + _vm._s(trip.product.name))]), trip.vehicle.name || trip.transportId ? _c('span', [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward ")]), _vm._v(_vm._s(trip.vehicle.name) + " " + _vm._s(trip.transportId) + " ")], 1) : _vm._e()]), _c('v-card-text', [_c('div', {
            staticClass: "text--primary"
          }, [_c('v-list', _vm._l(trip.passengers, function (passenger, i) {
            return _c('v-list-item', {
              key: i
            }, [_vm.showSelect.value ? _c('v-checkbox', {
              attrs: {
                "value": passenger.externalItemReferences
              },
              model: {
                value: _vm.selectedChildItems,
                callback: function ($$v) {
                  _vm.selectedChildItems = $$v;
                },
                expression: "selectedChildItems"
              }
            }) : _vm._e(), _c('v-list-item-title', [_c('div', {
              staticClass: "name"
            }, [passenger.personalInfo ? _c('span', [_vm._v(" " + _vm._s(passenger.personalInfo.firstName) + " " + _vm._s(passenger.personalInfo.lastName) + " ")]) : _vm._e()]), _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-seat-recline-normal")]), _vm._v(" " + _vm._s(passenger.seatNumber || "-") + " ")], 1), _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-train-car-passenger")]), _vm._v(" " + _vm._s(passenger.carriageNumber || "-") + " ")], 1), passenger.ageGroup && passenger.ageGroup.name ? _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-slash-forward")]), _vm._v(" " + _vm._s(passenger.ageGroup.name) + " ")], 1) : _vm._e(), passenger.comfort ? _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-slash-forward")]), _vm._v(" " + _vm._s(_vm.getTripPassengerSeatDetailsString(passenger)) + " ")], 1) : _vm._e(), passenger.options && passenger.options.length > 0 ? _c('span', [_c('v-icon', {
              staticStyle: {
                "margin": "0 5px"
              },
              attrs: {
                "small": ""
              }
            }, [_vm._v("mdi-slash-forward")]), _vm._v(" " + _vm._s(passenger.options.join(", ")) + " ")], 1) : _vm._e()]), _c('v-spacer'), passenger.status !== 'pending' && passenger.status !== 'completed' ? _c('v-chip', {
              staticClass: "text-uppercase v-chipextra",
              style: {
                color: passenger.status === 'cancelled' ? 'white' : 'auto'
              },
              attrs: {
                "small": "",
                "color": _vm.getPassengerStatusColor(passenger.status)
              }
            }, [_vm._v(" " + _vm._s(passenger.status) + ": " + _vm._s(passenger.cancelInformation.amount.plainText) + " ")]) : _vm._e(), _c('span', {
              staticClass: "pr-4 pl-2",
              staticStyle: {
                "margin-right": "73px"
              }
            }, [_c('span', [_vm._v(" " + _vm._s(_vm.getReadableExternalIdsOfOrderItemJourneyTripPassenger(passenger)) + " ")])])], 1);
          }), 1)], 1)])], 1)], 1);
        }), 1)], 1) : item.type === 'taxi' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('v-timeline', {
          attrs: {
            "align-top": "",
            "dense": ""
          }
        }, [_c('v-timeline-item', {
          attrs: {
            "small": ""
          }
        }, [_c('v-card', {
          attrs: {
            "color": "grey lighten-4"
          }
        }, [_c('v-card-title', {
          staticClass: "text-subtitle-2"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).startNode.time)) + " • " + _vm._s(_vm.taxiItem(item).startNode.name))]), _c('v-icon', {
          staticStyle: {
            "margin": "0 10px"
          },
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', [_vm._v(_vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).endNode.time)) + " • " + _vm._s(_vm.taxiItem(item).endNode.name))])], 1), _c('v-card-subtitle', [_c('span', [_vm._v(_vm._s(_vm.getTaxiName(_vm.taxiItem(item))) + " • " + _vm._s(_vm._f("metersToKilometers")(_vm.taxiItem(item).distanceMeters)) + " • " + _vm._s(_vm._f("secondsToMinutes")(_vm.taxiItem(item).durationSeconds)))])]), _c('v-card-text', [_c('div', {
          staticClass: "text--primary"
        }, [_c('v-list', _vm._l(_vm.taxiItem(item).passengers, function (passenger, i) {
          return _c('v-list-item', {
            key: i
          }, [_c('v-list-item-title', [_c('div', {
            staticClass: "name",
            staticStyle: {
              "min-width": "300px"
            }
          }, [_c('span', [_vm._v(" " + _vm._s(passenger.firstName) + " " + _vm._s(passenger.lastName) + " ")]), passenger.isContact ? _c('v-chip', {
            attrs: {
              "small": "",
              "color": "primary"
            }
          }, [_vm._v(" Contact Person ")]) : _vm._e()], 1), _c('span', [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-phone")]), _vm._v(" " + _vm._s(passenger.phone || "-") + " ")], 1), _c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward")]), _c('span', [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-email")]), _vm._v(" " + _vm._s(passenger.email || "-") + " ")], 1), _c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-slash-forward")]), _c('span', [_c('v-icon', {
            staticStyle: {
              "margin": "0 5px"
            },
            attrs: {
              "small": ""
            }
          }, [_vm._v("mdi-cake-variant")]), _vm._v(" " + _vm._s(passenger.birthdate || "-") + " ")], 1)], 1)], 1);
        }), 1)], 1), _vm.taxiItem(item).startNode.description ? _c('v-card', {
          staticClass: "d-flex align-start mt-5 pa-3 font-weight-bold",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "accent"
          }
        }, [_vm._v("mdi-information")]), _c('div', {
          staticClass: "grey--text text--darken-2"
        }, [_c('div', {
          domProps: {
            "innerHTML": _vm._s(_vm.taxiItem(item).startNode.description)
          }
        })])], 1) : _vm._e(), _vm.taxiItem(item).infoToDriver ? _c('v-card', {
          staticClass: "d-flex align-start mt-5 pa-3 font-weight-bold",
          attrs: {
            "outlined": ""
          }
        }, [_c('v-icon', {
          staticClass: "mr-2",
          attrs: {
            "color": "blue-grey"
          }
        }, [_vm._v("mdi-note-text-outline")]), _c('span', {
          staticClass: "font-weight-bold mr-1"
        }, [_vm._v("Note for the driver: ")]), _c('span', {
          staticClass: "grey--text text--darken-2"
        }, [_vm._v(" " + _vm._s(_vm.taxiItem(item).infoToDriver))])], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1) : item.type === 'article' || item.type === 'distribution' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [item.description ? _c('div', {
          staticClass: "my-5 secondary--text"
        }, [_vm._v(" " + _vm._s(item.description) + " ")]) : _vm._e(), item.tickets && item.tickets.length > 0 ? _c('v-container', [_c('v-simple-table', {
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left"
              }, [_vm._v("Box Number")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v("Paper Number")]), _c('th', {
                staticClass: "text-left"
              }, [_vm._v("Used Date")])])]), _c('tbody', _vm._l(item.tickets, function (ticket) {
                return _c('tr', {
                  key: ticket.paperNumber
                }, [_c('td', [_vm._v(_vm._s(ticket.boxNumber))]), _c('td', [_vm._v(_vm._s(ticket.paperNumber))]), _c('td', [_vm._v(_vm._s(ticket.usedDate))])]);
              }), 0)];
            },
            proxy: true
          }], null, true)
        })], 1) : _vm._e()], 1) : _vm._e(), item.type === 'railpass' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "py-4"
        }, [_c('v-simple-table', {
          staticClass: "elevation-1",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Comfort")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Age Group")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Passenger")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Start Date")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Stop Date")])])]), _c('tbody', [_c('tr', {
                key: item.name
              }, [_c('td', [_vm._v(_vm._s(item.comfort.name))]), _c('td', [_vm._v(_vm._s(item.ageGroup.name))]), _c('td', [_vm._v(" " + _vm._s(item.passenger.firstName) + " " + _vm._s(item.passenger.lastName) + " ")]), _c('td', [_vm._v(_vm._s(item.startDate))]), _c('td', [_vm._v(_vm._s(item.stopDate))])])])];
            },
            proxy: true
          }], null, true)
        })], 1)]) : _vm._e(), item.type === 'giftcard' ? _c('td', {
          attrs: {
            "colspan": headers.length
          }
        }, [_c('div', {
          staticClass: "py-4"
        }, [_c('v-simple-table', {
          staticClass: "elevation-1",
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('thead', [_c('tr', [_c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Purpose")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Code")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Expiry date")]), _c('th', {
                staticClass: "text-left primary--text"
              }, [_vm._v("Remaining Amount")])])]), _c('tbody', [_c('tr', {
                key: item.name
              }, [_c('td', [_vm._v(_vm._s(item.purpose))]), _c('td', [_vm._v(_vm._s(item.giftCard.code))]), _c('td', [_vm._v(_vm._s(item.giftCard.expires))]), _c('td', [_vm._v(_vm._s(item.giftCard.remainingAmount))])])])];
            },
            proxy: true
          }], null, true)
        })], 1)]) : _vm._e()];
      }
    }, {
      key: `item.isPrinted`,
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm.isItemPrinted(item.id) ? _c('span', [_c('v-icon', [_vm._v("mdi-printer")])], 1) : _vm._e()];
      }
    }, {
      key: `item.type`,
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticStyle: {
            "display": "flex"
          }
        }, [_c('v-chip', {
          staticClass: "text-capitalize font-weight-medium",
          attrs: {
            "color": _vm.getTypeColor(item.type),
            "label": "",
            "small": "",
            "dark": ""
          }
        }, [_c('span', {
          class: item.type === 'taxi' ? 'taxi-text' : ''
        }, [_vm._v(" " + _vm._s(item.type) + " ")])]), _vm.isItemPrinted(item.id) ? _c('span', {
          staticClass: "ml-2"
        }, [_c('v-icon', [_vm._v("mdi-printer")])], 1) : _vm._e(), item.isInterrailSeatReservation && item.type === 'journey' && item.type === 'railpass' ? _c('span', {
          staticClass: "ml-2 d-flex align-center"
        }, [_c('img', {
          attrs: {
            "src": require("@/assets/interrail-logo.png"),
            "width": "20",
            "height": "18",
            "alt": ""
          }
        })]) : _vm._e()], 1)];
      }
    }, {
      key: `item.status`,
      fn: function (_ref9) {
        var item = _ref9.item;
        return [item.status ? _c('v-chip', {
          staticClass: "text-capitalize",
          attrs: {
            "color": _vm.getStatusColor(item.status),
            "label": "",
            "small": "",
            "outlined": ""
          }
        }, [_vm._v(" " + _vm._s(item.status.replace("_", " ")) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.itemdata`,
      fn: function (_ref10) {
        var item = _ref10.item;
        return [item.type === 'journey' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm._f("datetime")(item.departure.datetime)) + " • " + _vm._s(item.departure.locationName))]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(_vm._f("datetime")(item.arrival.datetime)) + " • " + _vm._s(item.arrival.locationName))])], 1) : item.type === 'taxi' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).startNode.time)) + " • " + _vm._s(_vm.taxiItem(item).startNode.name) + " ")]), _c('v-icon', {
          staticClass: "mx-2",
          attrs: {
            "small": ""
          }
        }, [_vm._v("mdi-arrow-right")]), _c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(" " + _vm._s(_vm._f("datetimeUTC")(_vm.taxiItem(item).endNode.time)) + " • " + _vm._s(_vm.taxiItem(item).endNode.name) + " ")])], 1) : item.type === 'railpass' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.name))])]) : item.type === 'distribution' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium mr-2"
        }, [_vm._v(_vm._s(item.title || item.slug) + " • " + _vm._s(item.minDeliveryDays) + " - " + _vm._s(item.maxDeliveryDays) + " days")]), item.trackingId ? _c('v-chip', {
          attrs: {
            "color": "grey lighten-4",
            "href": _vm.getTrackingLink(item),
            "target": "_blank",
            "link": "",
            "small": ""
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-truck-delivery")]), _vm._v(" Track shipment ")], 1) : _vm._e()], 1) : item.type === 'article' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.title))])]) : item.type === 'giftcard' ? _c('div', [_c('span', {
          staticClass: "font-weight-medium"
        }, [_vm._v(_vm._s(item.name))])]) : _vm._e()];
      }
    }, {
      key: `item.quantity`,
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.getQuantity(item)) + " ")];
      }
    }, {
      key: `item.refundValue`,
      fn: function (_ref12) {
        var item = _ref12.item;
        return [item.cancelInformation.amount.amount ? _c('div', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.cancelInformation.amount.plainText) + " ")]) : _vm._e()];
      }
    }, {
      key: `item.price`,
      fn: function (_ref13) {
        var item = _ref13.item;
        return [_c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(item.price.plainText) + " ")])];
      }
    }, {
      key: `item.externalIds`,
      fn: function (_ref14) {
        var item = _ref14.item;
        return [item.type === 'taxi' ? _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.taxiItem(item).ftresid))]) : _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(" " + _vm._s(_vm.getReadableExternalIdsOfOrderItem(item)))])];
      }
    }, {
      key: `item.actions`,
      fn: function (_ref15) {
        var item = _ref15.item;
        return [_c('div', {
          staticClass: "d-flex justify-end"
        }, [_c('v-tooltip', {
          attrs: {
            "color": "black",
            "max-width": "400",
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function (_ref16) {
              var on = _ref16.on,
                attrs = _ref16.attrs;
              return [_c('div', _vm._g(_vm._b({}, 'div', attrs, false), on), [['giftcard', 'article', 'distribution'].includes(item.type) && !_vm.showSelect.value && !item.isPaid ? _c('v-switch', {
                staticClass: "lock",
                attrs: {
                  "hide-details": ""
                },
                on: {
                  "change": function ($event) {
                    return _vm.handleLockToggle(item.id, item.lockedForRemoval);
                  }
                },
                model: {
                  value: item.lockedForRemoval,
                  callback: function ($$v) {
                    _vm.$set(item, "lockedForRemoval", $$v);
                  },
                  expression: "item.lockedForRemoval"
                }
              }) : _vm._e()], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v(_vm._s(item.lockedForRemoval ? _vm.lockedMessage : _vm.unlockedMessage))])])], 1), item.type === 'giftcard' && item.isPaid && item.purpose === 'purchasing' ? _c('GiftCardPdf', {
          attrs: {
            "disabled": item.status !== 'completed',
            "tooltipMessage": "PDF access is not allowed for gift cards that are not completed.",
            "giftCardCode": item.giftCard.code
          }
        }) : _vm._e(), item.type === 'distribution' && item.trackingId ? _c('v-btn', {
          staticClass: "ml-2 danger white--text",
          attrs: {
            "loading": _vm.loading && _vm.clickedButton === `distribution_cancel_${item.id}`,
            "small": ""
          },
          on: {
            "click": function ($event) {
              _vm.clickedButton = `distribution_cancel_${item.id}`;
              _vm.cancelDistribution({
                orderId: _vm.orderId,
                itemId: item.id
              });
            }
          }
        }, [_vm._v(" CANCEL TO RE-PRINT ")]) : _vm._e()];
      }
    }], null, true),
    model: {
      value: _vm.selectedItems,
      callback: function ($$v) {
        _vm.selectedItems = $$v;
      },
      expression: "selectedItems"
    }
  }), _vm.inquiryId ? _c('v-dialog', {
    attrs: {
      "max-width": "700px"
    },
    model: {
      value: _vm.cancelModal,
      callback: function ($$v) {
        _vm.cancelModal = $$v;
      },
      expression: "cancelModal"
    }
  }, [_c('v-card', {
    attrs: {
      "disabled": _vm.$store.state.loading
    }
  }, [_c('v-flex', {
    staticClass: "mb-12"
  }), _c('base-material-card', {
    attrs: {
      "color": "indigo lighten-2",
      "title": "Add cancel information"
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function () {
        return [_vm.item.type !== 'distribution' && _vm.item.status !== 'pending' && _vm.item.status !== 'completed' && _vm.item.status !== 'refunded' ? _c('v-btn', {
          attrs: {
            "loading": _vm.loading,
            "color": "danger",
            "outlined": ""
          },
          on: {
            "click": function ($event) {
              _vm.cancelModal = false;
            }
          }
        }, [_vm._v(" Not Now ")]) : _vm._e(), _c('v-spacer'), _c('v-btn', {
          attrs: {
            "loading": _vm.$store.state.loading,
            "color": "success"
          },
          on: {
            "click": _vm.addCancelInfo
          }
        }, [_vm._v(" Save ")])];
      },
      proxy: true
    }], null, false, 3635568603)
  }, [_c('v-card-text', [_c('ValidationObserver', {
    ref: "cancelObserver"
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "reason",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref17) {
        var errors = _ref17.errors;
        return [_c('v-textarea', {
          attrs: {
            "filled": "",
            "dense": "",
            "hide-details": "",
            "label": "Reason of cancellation",
            "error-messages": errors,
            "clearable": ""
          },
          model: {
            value: _vm.editedItem.reason,
            callback: function ($$v) {
              _vm.$set(_vm.editedItem, "reason", $$v);
            },
            expression: "editedItem.reason"
          }
        })];
      }
    }], null, false, 3363700290)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "otherReason",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref18) {
        var errors = _ref18.errors;
        return [_c('v-textarea', {
          attrs: {
            "filled": "",
            "dense": "",
            "hide-details": "",
            "label": "Other Reason of cancellation",
            "error-messages": errors,
            "clearable": ""
          },
          model: {
            value: _vm.editedItem.otherReason,
            callback: function ($$v) {
              _vm.$set(_vm.editedItem, "otherReason", $$v);
            },
            expression: "editedItem.otherReason"
          }
        })];
      }
    }], null, false, 662129986)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": "amount",
      "rules": "required|numeric|min:0"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref19) {
        var errors = _ref19.errors;
        return [_c('v-text-field', {
          attrs: {
            "type": "number",
            "filled": "",
            "dense": "",
            "hide-details": "",
            "label": "amount of refund",
            "error-messages": errors,
            "clearable": ""
          },
          model: {
            value: _vm.editedItem.amount,
            callback: function ($$v) {
              _vm.$set(_vm.editedItem, "amount", $$v);
            },
            expression: "editedItem.amount"
          }
        })];
      }
    }], null, false, 2854240270)
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1) : _vm._e(), _c('AddNewItemModal', {
    attrs: {
      "tenantName": _vm.tenantName,
      "canUseGiftcard": _vm.canUseGiftcard,
      "is-visible": _vm.addItemModalVisible
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.addItemModalVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.addItemModalVisible = $event;
      }
    },
    scopedSlots: _vm._u([{
      key: "gift-card",
      fn: function () {
        return [_c('GiftCardForm', {
          attrs: {
            "order-id": _vm.orderId,
            "currency": _vm.baseCurrency,
            "is-visible": _vm.addItemModalVisible
          },
          on: {
            "update:isVisible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "update:is-visible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "itemAddedOrRemove": function ($event) {
              return _vm.addedOrRemoveItem($event);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "article",
      fn: function () {
        return [_c('ArticleForm', {
          attrs: {
            "order-id": _vm.orderId,
            "registered-articles": _vm.articles,
            "tenant-id": _vm.tenantId,
            "currency": _vm.baseCurrency,
            "locale": _vm.locale,
            "is-visible": _vm.addItemModalVisible,
            "is-international": _vm.isInternational
          },
          on: {
            "update:isVisible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "update:is-visible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "itemAddedOrRemove": function ($event) {
              return _vm.addedOrRemoveItem($event);
            }
          }
        })];
      },
      proxy: true
    }, {
      key: "distribution",
      fn: function () {
        return [_c('DistributionForm', {
          attrs: {
            "order-id": _vm.orderId,
            "registered-articles": _vm.articles,
            "tenant-id": _vm.tenantId,
            "currency": _vm.baseCurrency,
            "locale": _vm.locale,
            "is-visible": _vm.addItemModalVisible,
            "is-international": _vm.isInternational
          },
          on: {
            "update:isVisible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "update:is-visible": function ($event) {
              _vm.addItemModalVisible = $event;
            },
            "itemAddedOrRemove": function ($event) {
              return _vm.addedOrRemoveItem($event);
            }
          }
        })];
      },
      proxy: true
    }])
  }), _c('SpecialRefund', {
    attrs: {
      "is-visible": _vm.refundItemModalVisible,
      "items": _vm.items,
      "printedItemIds": _vm.printedItemIds,
      "orderId": _vm.orderId,
      "bookings": _vm.bookings,
      "currency": _vm.baseCurrency,
      "taxiItem": _vm.taxiItem,
      "getTaxiName": _vm.getTaxiName
    },
    on: {
      "update:isVisible": function ($event) {
        _vm.refundItemModalVisible = $event;
      },
      "update:is-visible": function ($event) {
        _vm.refundItemModalVisible = $event;
      },
      "itemRefund": _vm.itemRefund
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }